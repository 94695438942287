import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(

    private authService: AuthService,
    private router: Router

  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const user = this.authService.GetUserName();

    if (user) {
      // logged in so return true
      this.authService.loggedIn.next(true);
      this.authService.setregisterpageStatus(false);
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.authService.loggedIn.next(false);
      this.authService.setregisterpageStatus(false);
      this.router.navigate(['/sign-in']);
      return false;
    }
  }


}
