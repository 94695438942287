import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../services/notification.service';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { ValidateMobile } from '../../../validators/custom.validator';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  ngProfileForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false; 

  constructor(
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private service: ProfileService,
  ) {

    this.ngProfileForm = this.fb.group({
      cust_name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', [Validators.required, ValidateMobile]],
      card_number: ['', Validators.required]
    });

  }

  ngOnInit(): void {

    this.getBasicProfile();

  }

  get f() { return this.ngProfileForm.controls; }

  getBasicProfile() {

    const email = this.authService.GetUserName();

    if (email) {

      
      this.service.getBasicProfile(email).pipe(first()).subscribe(
        (res: any) => {

          this.ngProfileForm.controls.cust_name.setValue(res.cust_name);
          this.ngProfileForm.controls.email.setValue(res.email);
          this.ngProfileForm.controls.mobile.setValue(res.mobile);
          this.ngProfileForm.controls.card_number.setValue(res.card_number);
        },
        error => {

        });
    }
  }

  onSubmit() {
    
    this.submitted = true;
    this.buttonDisabled = true;

    // stop here if form is invalid
    if (this.ngProfileForm.invalid) {
      return;
    }

    this.loading = true;

    this.service.UpdateBasicProfile(this.ngProfileForm.value).pipe(first()).subscribe(
      (res: any) => {

        this.notifyService.showSuccess("Profile updated successfully.");

        this.loading = false;
        this.buttonDisabled = false;
      },
      error => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }

}
