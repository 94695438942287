import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  baseUrl = environment.baseUrl;

  constructor(
    private httpClient: HttpClient
  ) { }


  //call on payment success
  success(returnstring: any) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/Account/success?returnstring=' + returnstring, httpOptions);
  }

  //call on payment decline by bank
  decline(returnstring: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/Account/decline?returnstring=' + returnstring, httpOptions);
  }

  //call on payment cancel by user
  cancel(returnstring: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/Account/cancel?returnstring=' + returnstring, httpOptions);
  }

  //account activation
  activation(activationcode: any) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.post(this.baseUrl + '/Account/activation', JSON.stringify(activationcode), httpOptions);

  }

}
