export class AirportBookingModel {
  full_name: string;
  email: string;
  mobile: string;
  alt_no: string;
  service_type: string;
  ticket_amount: string;
  ticket_date: string;
  ticket_file: string;
  no_of_adults: string;
  no_of_babySeats: string;
  no_of_luggages: string;
  additional_remarks: string;
  radioGroup: string;
  pickup_from: string;
  drop_to: string;
  address: string;
  landmark: string;
  emirate: string;
  area: string;
  pickup_date: string;
  pickup_time: string;
  flight_no: string;
  i_agree: string;
  user_name: string;
  bin_number: string;
}

export class GolfBookingModel {
  name: string;
  email: string;
  mobile: string;
  alt_mobile: string;
  service_type: string;
  membership_no: string;
  golfclub_name: string;
  priority_date1: string;
  priority_time1: string;
  priority_date2: string;
  priority_time2: string;
  priority_date3: string;
  priority_time3: string;
  i_agree: string;
  user_name: string;
  bin_number: string;
}
