import { AbstractControl } from '@angular/forms';

export function ValidateEmail(control: AbstractControl): { [key: string]: Boolean } | null {

    let regularExp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  
    if (control.value !== undefined && !regularExp.test(control.value)) {
      return { "isValidEmail": true };
    }
    return null;
  }

  export function ValidateMobile(control: AbstractControl): { [key: string]: Boolean } | null {

    let regularExp = /^((\\+91-?)|0)?[0-9]{10}$/;
  
    if (control.value !== undefined && !regularExp.test(control.value)) {
      return { "isValidMobile": true };
    }
    return null;
  }

  export function ValidateCaptcha(control: AbstractControl): { [key: string]: Boolean } | null {

    if (control.get('captcha').value !== control.get('NewCaptcha').value) {
      return { "isValidCaptcha": true };
    }
    return null;
  }
  
  export function ValidateConfirmPassword(control: AbstractControl): { [key: string]: Boolean } | null {
  
    if (control.get('newPassword').value !== control.get('confirmPassword').value) {
      return { "isConfirmPassword": true };
    }
    return null;
  }
