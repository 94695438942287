import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: PaymentService,
    private SpinnerService: NgxSpinnerService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    let returnstring;
    this.activatedRoute.queryParams.subscribe(params => {
      returnstring = params.returnstring;
    });

    if (this.authService.GetUserName()) {
      this.authService.loggedIn.next(true);
    }

    this.cancel(returnstring);

  }

  cancel(returnstring: any) {
    this.service.cancel(returnstring).pipe(first()).subscribe(
      (res: any) => {
      },
      error => {

      });

  }
}
