import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { ValidateConfirmPassword } from '../../validators/custom.validator';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  ngChangePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false;
  errors = [];
  isShowError = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private service: UserService,
    private notifyService: NotificationService,
    private authService: AuthService
  ) { }

  get f() { return this.ngChangePasswordForm.controls; }

  ngOnInit(): void {

    this.ngChangePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9]).{8,}')]],
      confirmPassword: ['', Validators.required],
    }, { validator: ValidateConfirmPassword });

  }

  onSubmit() {


    // stop here if form is invalid
    if (this.ngChangePasswordForm.invalid) {
      return;
    }

    this.submitted = true;
    this.buttonDisabled = true;

    let userName: string = this.authService.GetUserName();

    this.loading = true;

    this.service.changePassword(this.ngChangePasswordForm.value, userName).pipe(first()).subscribe(
      (res: any) => {
        this.notifyService.showInfo("Password Changed Successfully.");
        this.loading = false;
        this.buttonDisabled = false;
        this.authService.logout();
      },
      error => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }
}
