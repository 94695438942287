import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './Modules/app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialUiModule } from './Modules/material-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { RegisterComponent } from './components/register/register.component';
import { AirportComponent } from './components/airport/airport.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GolfComponent } from './components/golf/golf.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './Modules/app-interceptor';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SpinnerInterceptor } from './Modules/spinner-interceptor';
import { PreauthComponent, SafePipe } from './components/preauth/preauth.component';
import { SuccessComponent } from './components/success/success.component';
import { CancelComponent } from './components/cancel/cancel.component';
import { DeclineComponent } from './components/decline/decline.component';
import { ActivationComponent } from './components/activation/activation.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AddressDetailComponent } from './components/profile/address-detail/address-detail.component';
import { BookingHistoryComponent } from './components/profile/booking-history/booking-history.component';
import { MyProfileComponent } from './components/profile/my-profile/my-profile.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { NewAddressDialogComponent } from './dialogs/new-address-dialog/new-address-dialog.component';
import { EditAddressDialogComponent } from './dialogs/edit-address-dialog/edit-address-dialog.component';
import { AirportBookingModel, GolfBookingModel } from './Models/shared.model';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TandcComponent } from './components/tandc/tandc.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { RouterModule } from '@angular/router';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { MatDialogModule } from '@angular/material/dialog';

export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 70,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 4000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    HomeComponent,
    NavMenuComponent,
    AboutUsComponent,
    ForgotComponent,
    RegisterComponent,
    AirportComponent,
    GolfComponent,
    PreauthComponent,
    SafePipe,
    SuccessComponent,
    CancelComponent,
    DeclineComponent,
    ActivationComponent,
    ProfileComponent,
    AddressDetailComponent,
    BookingHistoryComponent,
    MyProfileComponent,
    ConfirmDialogComponent,
    NewAddressDialogComponent,
    EditAddressDialogComponent,
    NotFoundComponent,
    ChangePasswordComponent,
    TandcComponent,
    ContactUsComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialUiModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    FlexLayoutModule,
    NgxSpinnerModule,
    NotifierModule.withConfig(customNotifierOptions),
    MatDialogModule,
  ],
  exports: [],
  providers: [
    CollapseModule,
    BsDropdownModule,
    MatDatepickerModule,
    AirportBookingModel,
    GolfBookingModel,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
