import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { AirportService } from '../../services/airport.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-address-dialog',
  templateUrl: './edit-address-dialog.component.html',
  styleUrls: ['./edit-address-dialog.component.css']
})
export class EditAddressDialogComponent implements OnInit {

  ngEditAddressForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false;
  errors = [];
  isShowError = false;
  Emirates = [];
  Areas = [];
  emailDisable = false;

  constructor(
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private notifyService: NotificationService,
    private airportService: AirportService,
    private authService: AuthService,
    private service: ProfileService,
    public dialogRef: MatDialogRef<EditAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.ngEditAddressForm = this.fb.group({
      address: ['', Validators.required],
      landmark: ['', Validators.required],
      emirate: ['', Validators.required],
      area: ['', Validators.required],
      addressID: [this.data.registerid]
    });

  }

  get f() { return this.ngEditAddressForm.controls; }

  ngOnInit(): void {

    this.getEmiratesList();
    this.ShowAddressToEdit(this.data.registerid)

  }

  getEmiratesList() {

    setTimeout(() => {
      this.SpinnerService.show();
    });

    this.airportService.getEmiratesList().pipe(first()).subscribe(
      (res: any) => {

        this.Emirates = res;

        this.SpinnerService.hide();

      },
      error => {
        this.SpinnerService.hide();
      });
  }

  getAreaList(emirate) {

    this.airportService.getAreaList(emirate).pipe(first()).subscribe(
      (res: any) => {
        this.Areas = res;
      },
      error => {
      });
  }


  ShowAddressToEdit(value) {

    this.service.ShowAddressToEdit(value).pipe(first()).subscribe(
      (res: any) => {

        if (res != null) {

          this.ngEditAddressForm.controls.address.setValue(res.address);
          this.ngEditAddressForm.controls.landmark.setValue(res.landmark);
          this.ngEditAddressForm.controls.emirate.setValue(res.emirate);

          this.getAreaList(res.emirate);

          this.ngEditAddressForm.controls.area.setValue(res.area);
        }
      },
      error => {
      });
  }

  //*******get value of each control to submit

  get address(): any {
    return this.ngEditAddressForm.get('address').value;
  }

  get landmark(): any {
    return this.ngEditAddressForm.get('landmark').value;
  }

  get emirate(): any {
    return this.ngEditAddressForm.get('emirate').value;
  }

  get area(): any {
    return this.ngEditAddressForm.get('area').value;
  }

  get addressID(): any {
    return this.data.registerid;
  }

  onSubmit() {

    this.isShowError = false;
    this.submitted = true;
    this.buttonDisabled = true;

    // stop here if form is invalid
    if (this.ngEditAddressForm.invalid) {
      return;
    }

    this.loading = true;

    this.service.editAddress(this.ngEditAddressForm.value).pipe(first()).subscribe(
      (res: any) => {
        this.notifyService.showSuccess("Address updated successfully.");

        this.loading = false;
        this.buttonDisabled = false;
        this.dialogRef.close();
      },
      error => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }

}
