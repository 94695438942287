import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ValidateEmail } from '../../validators/custom.validator';
import { first } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  ngForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private service: UserService,
    private notifyService: NotificationService
  ) { }

  ngOnInit(): void {

    this.authService.setregisterpageStatus(true);

    this.ngForm = this.formBuilder.group({
      email: ['', [Validators.required, ValidateEmail]]
    });

  }

  get f() { return this.ngForm.controls; }

  onSubmit() {

    this.submitted = true;
    this.buttonDisabled = true;

    // stop here if form is invalid
    if (this.ngForm.invalid) {
      return;
    }

    this.loading = true;

    this.service.sendPassword(this.ngForm.value).pipe(first()).subscribe(
      (res: any) => {

        this.notifyService.showSuccess('Great! You will receive your account password email in few minutes.');

        this.loading = false;
        this.buttonDisabled = false;
      },
      error => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }
}
