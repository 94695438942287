import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(public dialogRef: MatDialog, private authService: AuthService,) { }

  ngOnInit(): void {
    this.authService.setregisterpageStatus(true);
  }

}
