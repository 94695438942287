import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { HomeComponent } from '../components/home/home.component';
import { AboutUsComponent } from '../components/about-us/about-us.component';
import { ForgotComponent } from '../components/forgot/forgot.component';
import { RegisterComponent } from '../components/register/register.component';
import { AirportComponent } from '../components/airport/airport.component';
import { GolfComponent } from '../components/golf/golf.component';
import { AuthenticationGuard } from '../services/authentication.guard';
import { PreauthComponent } from '../components/preauth/preauth.component';
import { SuccessComponent } from '../components/success/success.component';
import { CancelComponent } from '../components/cancel/cancel.component';
import { DeclineComponent } from '../components/decline/decline.component';
import { ActivationComponent } from '../components/activation/activation.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { AddressDetailComponent } from '../components/profile/address-detail/address-detail.component';
import { MyProfileComponent } from '../components/profile/my-profile/my-profile.component';
import { BookingHistoryComponent } from '../components/profile/booking-history/booking-history.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { TandcComponent } from '../components/tandc/tandc.component';
import { ContactUsComponent } from '../components/contact-us/contact-us.component';
import { PrivacyComponent } from '../components/privacy/privacy.component';

const routes: Routes = [
  
  { path: '', component: SignInComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'preauth', component: PreauthComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'cancel', component: CancelComponent },
  { path: 'decline', component: DeclineComponent },
  { path: 'activation', component: ActivationComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthenticationGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'tandc', component: TandcComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'airport', component: AirportComponent, canActivate: [AuthenticationGuard] },
  { path: 'golf', component: GolfComponent, canActivate: [AuthenticationGuard] },
  {
    path: 'profile', component: ProfileComponent, canActivate: [AuthenticationGuard],
    children: [
      { path: '', component: AddressDetailComponent, canActivate: [AuthenticationGuard] },
      { path: 'address-detail', component: AddressDetailComponent, canActivate: [AuthenticationGuard] },
      { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthenticationGuard] },
      { path: 'booking-history', component: BookingHistoryComponent, canActivate: [AuthenticationGuard] }
    ]
  },
  { path: "**", component: NotFoundComponent } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
