import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-tandc',
  templateUrl: './tandc.component.html',
  styleUrls: ['./tandc.component.css']
})


export class TandcComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.setregisterpageStatus(true);
  }

}
