import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  ngForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false;
  btnText = 'Sign in';

  getloginResp: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private notifyService: NotificationService
  ) { }

  ngOnInit(): void {

    this.authService.setregisterpageStatus(false);

    this.ngForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f() {
    return this.ngForm.controls;
  }

  onSubmit() {

    // stop here if form is invalid
    if (this.ngForm.invalid) {
      return;
    }

    this.loading = true;
    this.submitted = true;
    this.buttonDisabled = true;

    this.authService
      .login(this.ngForm.value).pipe(first()).subscribe(
        (res: any) => {
          sessionStorage.setItem('access_token', res.token);
          sessionStorage.setItem('refresh_token', res.refreshToken);
          sessionStorage.setItem('fullname', res.cust_name);
          sessionStorage.setItem('username', res.username);
          sessionStorage.setItem('cardno', res.card_number);
          sessionStorage.setItem('isLoggedIn', 'true');
          this.authService.loggedIn.next(true);
          this.authService.setregisterpageStatus(false);
          this.notifyService.showSuccess('Your login was successfull.');
          this.loading = false;
          this.buttonDisabled = false;
          this.router.navigateByUrl('/home');
        },
        (error) => {
          this.loading = false;
          this.buttonDisabled = false;
        }
      );
  }
}
