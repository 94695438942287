import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-preauth',
  templateUrl: './preauth.component.html',
  styleUrls: ['./preauth.component.css']
})
export class PreauthComponent implements OnInit {

  urlSafe: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: UserService,
    private SpinnerService: NgxSpinnerService,
    private notifyService: NotificationService,
  ) { }

  ngOnInit(): void {

    let rid;
    this.activatedRoute.queryParams.subscribe(params => {
      rid = params.rid;
    });

    this.initializepreAuth(rid);
  }

  initializepreAuth(rid: any) {

    this.service.InitializePreAuth(rid).pipe(first()).subscribe(
      (res: any) => {

        const urls = res.map(x => x.respValue);
        this.urlSafe = urls;
      },
      error => {
        
      });

  }

}
