import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(
    private authService: AuthService

  ) { }

  ngOnInit(): void {
  }

  get CustomerName(): any {
    return this.authService.GetUser();
  }

  get CardNumber(): any {
    return this.authService.GetCardNumber();
  }

}
