import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ValidateEmail, ValidateMobile } from '../../validators/custom.validator';
import { AuthService } from '../../services/auth.service';
import { AirportService } from '../../services/airport.service';
import { first } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import { forkJoin } from 'rxjs';
import { AirportBookingModel } from '../../Models/shared.model';

@Component({
  selector: 'app-airport',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.css']
})
export class AirportComponent implements OnInit {

  baseForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  formFinalGroup: FormGroup;

  urlSafe: string;

  loading = false;
  submitted = false;
  buttonDisabled = false;

  isAddress = false;

  isSteeperValid = true;
  isComplementary = true;
  isPaidSelected = false;
  trans_amt: any;

  PickUpFrom = [];
  DropUpTo = [];
  Emirates = [];
  Areas = [];
  PickupTimes = [];
  errors = [];
  blockDates = [];
  CustomerInfo: any;

  selectedFile: string = null;

  @ViewChild('stepper') stepper: MatStepper;
  isLinear: boolean = true;
  disableFutureDate = new Date();
  todayDate: Date = new Date();
  someDate: Date = new Date(this.todayDate.getDate() - 1);

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private service: AirportService,
    private model: AirportBookingModel,
    
  ) {
    this.disableFutureDate.setDate(this.disableFutureDate.getDate());

  }

  get f() { return this.firstFormGroup.controls; }
  get ff() { return this.formFinalGroup.controls; }

  ngOnInit(): void {

    this.isAddressVailable();

    this.firstFormGroup = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', [Validators.required, ValidateEmail]],
      mobile: ['', [Validators.required, ValidateMobile]],
      alt_no: [''],
      service_type: ['', Validators.required]
    });

    this.secondFormGroup = this.formBuilder.group({
      ticket_amount: ['', [Validators.required, Validators.pattern(/^[.\d]+$/)]],
      ticket_date: ['', Validators.required],
      ticket_file: [],
      fileSource: []
    });

    this.thirdFormGroup = this.formBuilder.group({
      no_of_adults: ['1', Validators.required],
      no_of_babySeats: ['0'],
      no_of_luggages: ['0'],
      additional_remarks: [''],
    });

    this.fourthFormGroup = this.formBuilder.group({
      radioGroup: ['', Validators.required],
      pickup_from: ['', Validators.required],
      drop_to: ['', Validators.required],
      address: ['', Validators.required],
      landmark: [''],
      emirate: ['', Validators.required],
      area: ['', Validators.required],
      pickup_date: ['', Validators.required],
      pickup_time: ['', Validators.required],
      flight_no: ['', Validators.required]
    });

    this.formFinalGroup = this.formBuilder.group({
      i_agree: [false, [Validators.requiredTrue]]
    });

    this.getMultipleRequest();  

    //booking date validation 72 hours check and also before 5.30PM chck and after 5.30PM check
    var interval = 3;
    let aestTime: any = new Date().toLocaleString("en-US", { timeZone: "Asia/Dubai" });
    aestTime = new Date(aestTime);
    var time = aestTime.getHours() + ":" + aestTime.getMinutes() + ":" + aestTime.getSeconds();
    var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var weekDayName = weekday[aestTime.getDay()];

    if (weekDayName == "Thursday") {
      if (aestTime.getHours() > 17 && aestTime.getMinutes() > 30) {
        interval = 6;
      }
      else {
        interval = 5;
      }
    }
    else if (weekDayName == "Friday") {
      if (aestTime.getHours() > 17 && aestTime.getMinutes() > 30) {
        interval = 5;
      }
      else {
        interval = 5;
      }
    }
    else {

      if (aestTime.getHours() > 17 && aestTime.getMinutes() > 30) {
        interval = 5;
      }
      else {
        interval = 4;
      }
    }

    this.addDays(this.todayDate, interval);

  }

  getMultipleRequest() {

    //call multiple http request sequentaly using forkJoin. this will allow to finish http request one by one.

    let response1 = this.service.getBlockedDateList();
    let response2 = this.service.getEmiratesList();
    let response3 = this.service.getPickupTimeList();
    let response4 = this.service.getCustomerDetails(this.authService.GetUserName());

    forkJoin([response1, response2, response3, response4]).subscribe((results: any) => {
      this.blockDates = results[0];
      this.Emirates = results[1];
      this.PickupTimes = results[2];

      let CustomerInfo = results[3];

      if (CustomerInfo) {
        this.firstFormGroup.controls.full_name.setValue(CustomerInfo.cust_name);
        this.firstFormGroup.controls.email.setValue(CustomerInfo.email);
        this.firstFormGroup.controls.mobile.setValue(CustomerInfo.mobile);
      }
    });
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }


  //*******get value of each control to submit

  get full_name(): any {
    return this.firstFormGroup.get('full_name').value;
  }

  get email(): any {
    return this.firstFormGroup.get('email').value;
  }

  get mobile(): any {
    return this.firstFormGroup.get('mobile').value;
  }

  get alt_no(): any {
    return this.firstFormGroup.get('alt_no').value;
  }

  get service_type(): any {
    return this.firstFormGroup.get('service_type').value;
  }

  get ticket_amount(): any {
    return this.secondFormGroup.get('ticket_amount').value;
  }

  get ticket_date(): any {
    return this.secondFormGroup.get('ticket_date').value;
  }

  get ticket_file(): any {
    return this.secondFormGroup.get('ticket_file').value;
  }

  get no_of_adults(): any {
    return this.thirdFormGroup.get('no_of_adults').value;
  }

  get no_of_babySeats(): any {
    return this.thirdFormGroup.get('no_of_babySeats').value;
  }

  get no_of_luggages(): any {
    return this.thirdFormGroup.get('no_of_luggages').value;
  }

  get additional_remarks(): any {
    return this.thirdFormGroup.get('additional_remarks').value;
  }

  get radioGroup(): any {
    return this.fourthFormGroup.get('radioGroup').value;
  }

  get pickup_from(): any {
    return this.fourthFormGroup.get('pickup_from').value;
  }

  get drop_to(): any {
    return this.fourthFormGroup.get('drop_to').value;
  }

  get address(): any {
    return this.fourthFormGroup.get('address').value;
  }

  get landmark(): any {
    return this.fourthFormGroup.get('landmark').value;
  }

  get emirate(): any {
    return this.fourthFormGroup.get('emirate').value;
  }

  get area(): any {
    return this.fourthFormGroup.get('area').value;
  }

  get pickup_date(): any {
    return this.fourthFormGroup.get('pickup_date').value;
  }

  get pickup_time(): any {
    return this.fourthFormGroup.get('pickup_time').value;
  }

  get flight_no(): any {
    return this.fourthFormGroup.get('flight_no').value;
  }

  get i_agree(): any {
    return this.formFinalGroup.get('i_agree').value;
  }

  //********************

  pickupDateFilter = (d: Date): boolean => {

    //get date of local date region
    let aestTime: any = d.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
    aestTime = new Date(aestTime);

    //date format should be d/m/yyyy ie. 29/6/2020 or 1/7/2020
    var date = aestTime.getDate() + "/" + (aestTime.getMonth() + 1) + "/" + aestTime.getFullYear();

    //get week number of dates.
    var dd = d.getDay();

    //block/disable dates from array and Friday (week number 5) should be blocked

    //return (!this.blockDates.includes(date) && dd !== 5);

    return (!this.blockDates.includes(date));
  }

  IsComplimentaryAvailable(value: any) {

    if (value == 'Complimentary') {

      this.isPaidSelected = false;
      
      let userName = this.authService.GetUserName();
      this.service.CheckAirportServiceAvailable(userName).pipe(first()).subscribe(
        (res: any) => {
          const val = res.resp[0];
          if (val > 0) {
            this.isComplementary = true;
          }
          else {
            this.firstFormGroup.get('service_type').setErrors({ 'incorrect': true });
            this.isComplementary = false;
          }
        },
        error => {
        });
    }
    else {
      this.isComplementary = true;
      this.isPaidSelected = true;
    }

  }

  public uploadTicketFile = (files) => {

    if (files.target.files.length === 0) {
      return;
    }

    let fileToUpload = <File>files.target.files[0];

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('user', this.authService.GetUserName());

    this.service.uploadTicketFile(formData).pipe(first()).subscribe(
      (res: any) => {
        this.selectedFile = res.dbPath;
      },
      error => {

      });
  }

  validatePassengerForm() {

    let adults: number = this.no_of_adults;
    let baby: number = this.no_of_babySeats;
    let lugg: number = this.no_of_luggages;

    adults = adults == 0 ? 0 : adults;
    baby = baby == 0 ? 0 : baby;
    lugg = lugg == 0 ? 0 : lugg;

    let total: number = +adults + +baby + +lugg;

    console.log(total);

    if (total > 7) {
      this.isSteeperValid = false
    }
    else {
      this.stepper.next();
      this.isSteeperValid = true;
    }
  }

  getAddressType(value) {

    let userName = this.authService.GetUserName();

    this.service.getAddressType(userName).pipe(first()).subscribe(
      (res: any) => {        
        if (value == 'Airport Drop') {
          this.PickUpFrom = res.map(x => x.address_type);
        }
        else {
          this.DropUpTo = res.map(x => x.address_type);
        }
      },
      error => {

      });
  }

  isAddressVailable() {

    let userName = this.authService.GetUserName();

    this.service.isAddressVailable(userName).pipe(first()).subscribe(
      (res: any) => {
        if (res == true) {
          this.isAddress = true;
        }
        else {
          this.isAddress = false;
        }
      },
      error => {

      });
  }

  getAirportList(value) {
    this.service.getAirportList().pipe(first()).subscribe(
      (res: any) => {

        if (value == 'Airport Drop') {
          this.DropUpTo = res;
        }
        else {
          this.PickUpFrom = res;
        }
      },
      error => {
      });
  }

  getAreaList(value) {

    this.service.getAreaList(value).pipe(first()).subscribe(
      (res: any) => {
        this.Areas = res;
      },
      error => {
      });
  }

  PopulateAddress(value) {

    let username = this.authService.GetUserName();

    this.service.GetAddress(username, value).pipe(first()).subscribe(
      (res: any) => {

        if (res !== null) {
          this.fourthFormGroup.controls.address.setValue(res.address);
          this.fourthFormGroup.controls.landmark.setValue(res.landmark);
          this.fourthFormGroup.controls.emirate.setValue(res.emirate);

          this.getAreaList(res.emirate);

          this.fourthFormGroup.controls.area.setValue(res.area);
        }
      },
      error => {
        
      });
  }

  radioChange(event: MatRadioChange) {

    //1-Airport Drop
    //2-Airport Pickup

    this.fourthFormGroup.controls.pickup_from.setValue(undefined);
    this.fourthFormGroup.controls.drop_to.setValue(undefined);

    if (event.value == 'Airport Drop') {
      this.getAddressType('Airport Drop');
      this.getAirportList('Airport Drop');
    }
    else {
      this.getAddressType('Airport Pickup');
      this.getAirportList('Airport Pickup');
    }
  }  

  pickup_from_v: any;
  pickup_date_v: any;
  pickup_time_v: any;
  flight_number_v: any;
  drop_off_to_v: any;
  address_v: any;
  emirateArea_v: any;

  PopulateVerifyDetails() {
    this.pickup_from_v = this.fourthFormGroup.controls.pickup_from.value;
    this.pickup_date_v = this.fourthFormGroup.controls.pickup_date.value;
    this.pickup_time_v = this.fourthFormGroup.controls.pickup_time.value
    this.flight_number_v = this.fourthFormGroup.controls.flight_no.value;
    this.drop_off_to_v = this.fourthFormGroup.controls.drop_to.value;
    this.address_v = this.fourthFormGroup.controls.address.value + ", " + this.fourthFormGroup.controls.landmark.value;
    this.emirateArea_v = this.fourthFormGroup.controls.emirate.value + ", " + this.fourthFormGroup.controls.area.value;
  }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.firstFormGroup.invalid && this.secondFormGroup.invalid && this.thirdFormGroup.invalid && this.fourthFormGroup.invalid && this.formFinalGroup.invalid) {
      return;
    }

    //stop here if i_agree checkbox not checked
    if (this.i_agree == false) {
      return;
    }

    this.buttonDisabled = true;
    this.loading = true;

    this.model.full_name = this.full_name;
    this.model.email = this.email;
    this.model.mobile = this.mobile;
    this.model.alt_no = this.alt_no;
    this.model.service_type = this.service_type;
    this.model.ticket_amount = this.ticket_amount;
    this.model.ticket_date = this.ticket_date;
    this.model.ticket_file = this.selectedFile;
    this.model.no_of_adults = this.no_of_adults;
    this.model.no_of_babySeats = this.no_of_babySeats;
    this.model.no_of_luggages = this.no_of_luggages;
    this.model.additional_remarks = this.additional_remarks;
    this.model.radioGroup = this.radioGroup;
    this.model.pickup_from = this.pickup_from;
    this.model.drop_to = this.drop_to;
    this.model.address = this.address;
    this.model.landmark = this.landmark;
    this.model.emirate = this.emirate;
    this.model.area = this.area;
    this.model.pickup_date = this.pickup_date;
    this.model.pickup_time = this.pickup_time;
    this.model.flight_no = this.flight_no;
    this.model.i_agree = this.i_agree;
    this.model.user_name = this.authService.GetUserName();
    this.model.bin_number = this.authService.GetCardNumber();

    this.service.SubmitAirportTransforForm(this.model).subscribe(
      (res: any) => {

        const guid = res.map(x => x.respValue);

        this.service.InitAirportPaymentGateway(guid).pipe(first()).subscribe(
          (res: any) => {

            const urls = res.map(x => x.respValue);
            this.trans_amt = res.map(x => x.respValue1);

            this.urlSafe = urls;
            this.loading = false;
            this.buttonDisabled = false;

            this.stepper.selected.completed = true;
            this.stepper.next();
          },
          error => {
            this.loading = false;
            this.buttonDisabled = false;
          });
      },
      error => {
        this.loading = false;
        this.buttonDisabled = false;
      });

  }

}

