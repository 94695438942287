import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AirportService {

  baseUrl = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
  ) { }

  GetTocken(): any {
    return sessionStorage.getItem('access_token');
  }

  //get address type to fill dropdown

  getAddressType(username) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' },
      params: { 'username': username }
    };

    return this.httpClient.get(this.baseUrl + '/airport/getAddressType', httpOptions);
  }

  isAddressVailable(username) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' },
      params: { 'username': username }
    };

    return this.httpClient.get(this.baseUrl + '/airport/isAddressVailable', httpOptions);
  }


  //get emirate list to populate dropdown
  getEmiratesList() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Accept': '*/*'
      })
    }

    return this.httpClient.get(this.baseUrl + '/airport/getEmiratesList', httpOptions);
  }

  //get area list to populate dropdown
  getAreaList(emirate) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' },
      params: { 'emirate': emirate }
    };

    return this.httpClient.get(this.baseUrl + '/airport/getAreaList', httpOptions);
  }

  //get airport list to populate dropdown
  getAirportList() {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
    };

    return this.httpClient.get(this.baseUrl + '/airport/getAirportList', httpOptions);
  }

  //get basic customer detail to start booking
  getCustomerDetails(email) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' },
      params: { 'email': email }
    };

    return this.httpClient.get(this.baseUrl + '/airport/getCustomerDetails', httpOptions);
  }

  //get address details to populate on booking page
  GetAddress(email, address_type) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' },
      params: { 'email': email, 'address_type': address_type }
    };

    return this.httpClient.get(this.baseUrl + '/airport/GetAddress', httpOptions);
  }

  //get pickupTime list to populate dropdown
  getPickupTimeList() {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
    };

    return this.httpClient.get(this.baseUrl + '/airport/getPickupTimeList', httpOptions);
  }

  //submit airport transfer form
  SubmitAirportTransforForm(formData) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
    }

    return this.httpClient.post(this.baseUrl + '/airport/SubmitAirportTransforForm', JSON.stringify(formData), httpOptions);
  }

  //invoke payment gatway for aurport booking url
  InitAirportPaymentGateway(cust_id: any) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/airport/InitAirportPaymentGateway?cust_id=' + cust_id, httpOptions);
  }

  //get blocked date list to block calender
  getBlockedDateList() {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/airport/getBlockedDateList', httpOptions);
  }

  //check if airport transfer complimentary service is available or not
  CheckAirportServiceAvailable(email) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/airport/CheckAirportServiceAvailable?email=' + email, httpOptions);
  }

  uploadTicketFile(formData: FormData) {

    //const httpOptions = {
    //  headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
    //}

    return this.httpClient.post(this.baseUrl + '/airport/uploadTicketFile', formData/*, httpOptions*/);
  }

}
