import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private registerpage$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  getregisterpageStatus(): Observable<boolean> {
    return this.registerpage$.asObservable();
  }

  setregisterpageStatus(status: boolean) {
    this.registerpage$.next(status);
  }

  constructor(private router: Router, private service: UserService) { }

  login(formdata: any) {
    return this.service.getTocken(formdata).pipe(
      map((res) => {
        return res;
      })
    );
  }

  logout() {
    this.loggedIn.next(false);
    this.setregisterpageStatus(false);
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  refreshToken() {
    return this.service.getRefreshToken(this.getRefreshToken()).pipe(
      map((res) => {
        return res;
      })
    );
  }

  GetUser(): any {
    return sessionStorage.getItem('fullname');
  }

  GetUserName(): any {
    return sessionStorage.getItem('username');
  }

  GetCardNumber(): any {
    return sessionStorage.getItem('cardno');
  }

  public getToken(): string {
    return sessionStorage.getItem('access_token');
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem('refresh_token');
  }
}
