import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.css']
})
export class DeclineComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: PaymentService,
    private SpinnerService: NgxSpinnerService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    let returnstring;
    this.activatedRoute.queryParams.subscribe(params => {
      returnstring = params.returnstring;
    });

    if (this.authService.GetUserName()) {
      this.authService.loggedIn.next(true);
    }

    this.decline(returnstring);

  }

  decline(returnstring: any) {

    this.service.decline(returnstring).pipe(first()).subscribe(
      (res: any) => {
        
      },
      error => {
        
      });

  }

}
