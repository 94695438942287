import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidateEmail, ValidateMobile } from '../../validators/custom.validator';
import { AuthService } from '../../services/auth.service';
import { AirportService } from '../../services/airport.service';
import { GolfService } from '../../services/golf.service';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { GolfBookingModel } from '../../Models/shared.model';

@Component({
  selector: 'app-golf',
  templateUrl: './golf.component.html',
  styleUrls: ['./golf.component.css'],
})
export class GolfComponent implements OnInit {

  baseForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  formFinalGroup: FormGroup;

  urlSafe: string;

  loading = false;
  submitted = false;
  buttonDisabled = false;

  isSteeperValid = true;
  isComplementary = true;
  isPaidSelected = false;
  trans_amt: any;

  blockDates = [];
  CustomerInfo: any;
  ClubList = [];

  todayDate: Date = new Date();
  someDate: Date = new Date(this.todayDate.getDate() - 1);

  //ClubList = [
  //  'Saadiyat Beach Golf Club',
  //  'Abu Dhabi',
  //  'Al Hamra Golf Club',
  //  'Ras Al-Khaimah',
  //  'Els Club',
  //  'Montgomerie Golf Club',
  //  'Arabian Ranches Golf Club',
  //];

  @ViewChild('stepper') stepper: MatStepper;
  isLinear: boolean = true;


  constructor(    
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private airportService: AirportService,
    private service: GolfService,
    private model: GolfBookingModel,
    
  ) { }

  get f() { return this.firstFormGroup.controls; }
  get ff() { return this.formFinalGroup.controls; }

  ngOnInit(): void {

    this.firstFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, ValidateEmail]],
      mobile: ['', [Validators.required, ValidateMobile]],
      alt_no: [''],
      service_type: ['', Validators.required]
    });

    this.secondFormGroup = this.formBuilder.group({
      membership_no: [''],
      golfclub_name: ['', Validators.required],
    });

    this.thirdFormGroup = this.formBuilder.group({
      priority_date1: ['', Validators.required],
      priority_time1: ['', Validators.required],
      priority_date2: ['', Validators.required],
      priority_time2: ['', Validators.required],
      priority_date3: ['', Validators.required],
      priority_time3: ['', Validators.required],
    });

    this.formFinalGroup = this.formBuilder.group({
      i_agree: [false, [Validators.requiredTrue]]
    });

    this.getMultipleRequest();

    //booking date validation 72 hours check and also before 5.30PM chck and after 5.30PM check
    var interval = 3;
    let aestTime: any = new Date().toLocaleString("en-US", { timeZone: "Asia/Dubai" });
    aestTime = new Date(aestTime);
    var time = aestTime.getHours() + ":" + aestTime.getMinutes() + ":" + aestTime.getSeconds();
    var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var weekDayName = weekday[aestTime.getDay()];

    if (weekDayName == "Thursday") {
      if (aestTime.getHours() > 17 && aestTime.getMinutes() > 30) {
        interval = 6;
      }
      else {
        interval = 5;
      }
    }
    else if (weekDayName == "Friday") {
      if (aestTime.getHours() > 17 && aestTime.getMinutes() > 30) {
        interval = 5;
      }
      else {
        interval = 5;
      }
    }
    else {

      if (aestTime.getHours() > 17 && aestTime.getMinutes() > 30) {
        interval = 5;
      }
      else {
        interval = 4;
      }
    }

    this.addDays(this.todayDate, interval);

  }

  getMultipleRequest() {

    //call multiple http request sequentaly using forkJoin. this will allow to finish http request one by one.

    let response1 = this.airportService.getBlockedDateList();
    let response2 = this.airportService.getCustomerDetails(this.authService.GetUserName());
    let response3 = this.service.getGolfCourseList();


    forkJoin([response1, response2, response3]).subscribe((results: any) => {

      this.blockDates = results[0];
      let CustomerInfo = results[1];
      this.ClubList = results[2];

      if (CustomerInfo) {
        this.firstFormGroup.controls.name.setValue(CustomerInfo.cust_name);
        this.firstFormGroup.controls.email.setValue(CustomerInfo.email);
        this.firstFormGroup.controls.mobile.setValue(CustomerInfo.mobile);
      }
    });
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  pickupDateFilter = (d: Date): boolean => {

    //get date of local date region
    let aestTime: any = d.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
    aestTime = new Date(aestTime);

    //date format should be d/m/yyyy ie. 29/6/2020 or 1/7/2020
    var date = aestTime.getDate() + "/" + (aestTime.getMonth() + 1) + "/" + aestTime.getFullYear();

    //get week number of dates.
    var dd = d.getDay();

    //block/disable dates from array and Friday (week number 5) should be blocked

    //return (!this.blockDates.includes(date) && dd !== 5);

    return (!this.blockDates.includes(date));
  }  

  IsComplimentaryAvailable(value: any) {

    if (value == 'Complimentary') {

      this.isPaidSelected = false;

      let userName = this.authService.GetUserName();
      this.service.CheckGolfServiceAvailable(userName).pipe(first()).subscribe(
        (res: any) => {

          const val = res.resp;
          if (val > 0) {
            this.isComplementary = true;
          }
          else {
            this.firstFormGroup.get('service_type').setErrors({ 'incorrect': true });
            this.isComplementary = false;
          }

        },
        error => {
        });
    }
    else {
      this.isComplementary = true;
      this.isPaidSelected = true;
    }

  }

  golfClub_name: any;
  p_date1: any;
  p_date2: any;
  p_date3: any;

  p_time1: any;
  p_time2: any;
  p_time3: any;

  PopulateVerifyDetails() {

    this.golfClub_name = this.secondFormGroup.controls.golfclub_name.value;

    this.p_date1 = this.thirdFormGroup.controls.priority_date1.value;
    this.p_time1 = this.thirdFormGroup.controls.priority_time1.value;

    this.p_date2 = this.thirdFormGroup.controls.priority_date2.value;
    this.p_time2 = this.thirdFormGroup.controls.priority_time2.value;

    this.p_date3 = this.thirdFormGroup.controls.priority_date3.value;
    this.p_time3 = this.thirdFormGroup.controls.priority_time3.value;

  }

  //*******get value of each control to submit

  get name(): any {
    return this.firstFormGroup.get('name').value;
  }
  get email(): any {
    return this.firstFormGroup.get('email').value;
  }

  get mobile(): any {
    return this.firstFormGroup.get('mobile').value;
  }
  get service_type(): any {
    return this.firstFormGroup.get('service_type').value;
  }

  get membership_no(): any {
    return this.secondFormGroup.get('membership_no').value;
  }
  get golfclub_name(): any {
    return this.secondFormGroup.get('golfclub_name').value;
  }

  get priority_date1(): any {
    return this.thirdFormGroup.get('priority_date1').value;
  }
  get priority_time1(): any {
    return this.thirdFormGroup.get('priority_time1').value;
  }
  get priority_date2(): any {
    return this.thirdFormGroup.get('priority_date2').value;
  }
  get priority_time2(): any {
    return this.thirdFormGroup.get('priority_time2').value;
  }
  get priority_date3(): any {
    return this.thirdFormGroup.get('priority_date3').value;
  }
  get priority_time3(): any {
    return this.thirdFormGroup.get('priority_time3').value;
  }

  get i_agree(): any {
    return this.formFinalGroup.get('i_agree').value;
  }


  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.firstFormGroup.invalid && this.secondFormGroup.invalid && this.thirdFormGroup.invalid && this.formFinalGroup.invalid) {
      return;
    }

    //stop here if i_agree checkbox not checked
    if (this.i_agree == false) {
      return;
    }

    this.buttonDisabled = true;
    this.loading = true;

    this.model.name = this.name;
    this.model.email = this.email;
    this.model.mobile = this.mobile;   
    this.model.service_type = this.service_type;
    this.model.membership_no = this.membership_no;
    this.model.golfclub_name = this.golfclub_name;
    this.model.priority_date1 = this.priority_date1;
    this.model.priority_time1 = this.priority_time1;
    this.model.priority_date2 = this.priority_date2;
    this.model.priority_time2 = this.priority_time2;
    this.model.priority_date3 = this.priority_date3;
    this.model.priority_time3 = this.priority_time3;
    this.model.i_agree = this.i_agree;
    this.model.user_name = this.authService.GetUserName();
    this.model.bin_number = this.authService.GetCardNumber();

    this.service.SubmitGolfBookingForm(this.model).subscribe(
      (res: any) => {

        const guid = res.map(x => x.respValue);

        this.service.InitGolfPaymentGateway(guid).pipe(first()).subscribe(
          (res: any) => {

            const urls = res.map(x => x.respValue);
            this.trans_amt = res.map(x => x.respValue1);

            this.urlSafe = urls;
            this.loading = false;
            this.buttonDisabled = false;

            this.stepper.selected.completed = true;
            this.stepper.next();
          },
          error => {
            this.loading = false;
            this.buttonDisabled = false;
          });
      },
      error => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }
}
