import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {

  errorMessage: string;
  successMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: PaymentService,
    private SpinnerService: NgxSpinnerService,

  ) { }

  ngOnInit() {

    let acode;
    this.activatedRoute.queryParams.subscribe(params => {
      acode = params.activationcode;
    });

    this.activateAccount(acode);
  }

  activateAccount(activationCode: any) {

    this.service.activation(activationCode).pipe(first()).subscribe(
      (res: any) => {
        this.successMessage = res.message       
      },
      error => {
        if (error.status == 400) {
          this.errorMessage = error.error.message;
        }
        else {
          this.errorMessage = error.statusText;
        }
      });
  }

}
