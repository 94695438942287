import { Component, OnInit, AfterContentChecked, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service'
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ValidateCaptcha } from '../../validators/custom.validator';
import { ValidateMobile } from '../../validators/custom.validator';
import { ValidateEmail } from '../../validators/custom.validator';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  ngSignUpForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false;
  errors = [];
  isShowError = false;
  btnText = "Create My Account";


  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private authService: AuthService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private notifyService: NotificationService
  ) { }



  ngOnInit(): void {

    this.authService.setregisterpageStatus(true);

    this.ngSignUpForm = this.formBuilder.group({
      card_number: ['', Validators.required],
      cust_name: ['', Validators.required],
      email: ['', [Validators.required, ValidateEmail]],
      mobile: ['', [Validators.required, ValidateMobile]],
      password: ['', Validators.required],
      captcha: ['', Validators.required],
      NewCaptcha: [this.randomString(6).toUpperCase(), Validators.required]
    }, { validator: ValidateCaptcha });
  }

  //Accept only number in card_number text box
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  randomString(length) {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
  }

  get f() { return this.ngSignUpForm.controls; }


  onSubmit() {
    debugger
    this.isShowError = false;
    this.submitted = true;
    this.buttonDisabled = true;
    this.SpinnerService.show();

    // stop here if form is invalid
    if (this.ngSignUpForm.invalid) {
      return;
    }

    this.loading = true;

    this.service.RegisterUser(this.ngSignUpForm.value).pipe(first()).subscribe(
      (res: any) => {
        const guid = res.map(x => x.respValue);
        this.isShowError = false;
        this.loading = false;
        this.buttonDisabled = false;
        this.router.navigate(['/preauth'], { queryParams: { rid: guid } });
      },
      error => {
        this.isShowError = true;
        this.loading = false;
        this.buttonDisabled = false;
      });
  }
}
