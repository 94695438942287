import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { ConfirmDialogService } from '../../../services/confirm-dialog.service';
import { ProfileService } from '../../../services/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { NewAddressDialogComponent } from '../../../dialogs/new-address-dialog/new-address-dialog.component';
import { EditAddressDialogComponent } from '../../../dialogs/edit-address-dialog/edit-address-dialog.component';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.css']
})
export class AddressDetailComponent implements OnInit {

  addressList = [];

  constructor(
    public dialog: MatDialog,
    private service: ProfileService,
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    private notifyService: NotificationService,
    private dialogsService: ConfirmDialogService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.getAddressList();

  }

  getAddressList() {

    const email = this.authService.GetUserName();

    if (email) {

      this.service.getAddressList(email).pipe(first()).subscribe(
        (res: any) => {
          this.addressList = res;          
        },
        error => {
          
        });

    }

  }

  deleteAddress(addressID) {

    this.dialogsService.confirm('Confirm Dialog', 'Are you sure you want to delete this?')
      .subscribe((res: any) => {
        if (res) {
          
          this.service.DeleteAddress(addressID).subscribe(
            (res: any) => {
              this.getAddressList();
              this.notifyService.showSuccess('Address deleted successfully.')
              
            },
            error => {
              
            }
          )
        }
      })
  }

  openAddDialog(): void {

    const dialogRef = this.dialog.open(NewAddressDialogComponent, {
      width: '400px'
    });
    const sub = dialogRef.componentInstance.onAdd.subscribe(() => {
      this.getAddressList();      
    });
    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentInstance.onAdd.unsubscribe();
    });

  }

  openEditDialog(rid: any): void {

    const dialogRef = this.dialog.open(EditAddressDialogComponent, {
      width: '550px',
      data: { registerid: rid }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAddressList();
    });
  }

}
