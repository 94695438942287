import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  //get tocken
  getTocken(formData) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*' }),
    };

    return this.httpClient.post(this.baseUrl + '/Login/Login', JSON.stringify(formData), httpOptions);
  }

  //get Refresh tocken
  getRefreshToken(refreshtoken: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*', })
    };

    const body = { refreshToken: refreshtoken };

    return this.httpClient.post(this.baseUrl + '/Login/RefreshToken', JSON.stringify(body), httpOptions);
  }

  //register
  RegisterUser(formData) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*', }),
    };

    return this.httpClient.post(
      this.baseUrl + '/Account/RegisterUser', JSON.stringify(formData), httpOptions);
  }

  //invoke payment gatway url
  InitializePreAuth(rid: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*', }),
    };

    return this.httpClient.get(
      this.baseUrl + '/Account/InitializePreAuth?rid=' + rid, httpOptions);
  }

  //send password
  sendPassword(formData) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*', }),
    };

    return this.httpClient.post(
      this.baseUrl + '/Account/sendPassword', JSON.stringify(formData), httpOptions);
  }

  //change password request
  changePassword(formData, email) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: '*/*', }),
    };

    const body = {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
      confirmPassword: formData.confirmPassword,
      email: email,
    };

    return this.httpClient.post(
      this.baseUrl + '/Account/changePassword',
      JSON.stringify(body),
      httpOptions
    );
  }
}
