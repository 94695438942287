import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { AirportService } from '../../services/airport.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-new-address-dialog',
  templateUrl: './new-address-dialog.component.html',
  styleUrls: ['./new-address-dialog.component.css']
})
export class NewAddressDialogComponent implements OnInit {

  ngNewAddressForm: FormGroup;
  loading = false;
  submitted = false;
  buttonDisabled = false;
  errors = [];
  isShowError = false;
  Emirates = [];
  Areas = [];
  emailDisable = false;

  constructor(
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private notifyService: NotificationService,
    private airportService: AirportService,
    private authService: AuthService,
    private service: ProfileService,
    public dialogRef: MatDialogRef<NewAddressDialogComponent>
  ) { }

  ngOnInit(): void {
    
    this.ngNewAddressForm = this.fb.group({
      address_type: ['', Validators.required],
      address: ['', Validators.required],
      landmark: [''],
      emirate: ['', Validators.required],
      area: ['', Validators.required],
      email: [this.authService.GetUserName(), Validators.required]
    });

    this.getEmiratesList();

  }

  get f() { return this.ngNewAddressForm.controls; }

  getEmiratesList() {

    this.airportService.getEmiratesList().pipe(first()).subscribe(
      (res: any) => {
        
        this.Emirates = res;
      },
      error => {
      });
  }

  getAreaList(value) {

    this.airportService.getAreaList(value).pipe(first()).subscribe(
      (res: any) => {
        this.Areas = res;
      },
      error => {
      });
  }

  onSubmit() {

    this.submitted = true;
    this.buttonDisabled = true;

    // stop here if form is invalid
    if (this.ngNewAddressForm.invalid) {
      return;
    }

    this.loading = true;

    this.service.addNewAddress(this.ngNewAddressForm.value).pipe(first()).subscribe(
      (res: any) => {
        this.notifyService.showSuccess("Address added successfully.");
        this.onAdd.emit();
        this.loading = false;
        this.buttonDisabled = false;
        this.dialogRef.close();
      },
      error => {        
        this.loading = false;
        this.buttonDisabled = false;
      });
  }

  onAdd = new EventEmitter();

}
