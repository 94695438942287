import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialog,
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.authService.setregisterpageStatus(true);
  }

  //onNoClick(): void {
  //  this.dialogRef.closeAll();
  //}
}
