import { Component, OnInit, AfterContentInit, AfterViewInit, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';
import { AboutUsComponent } from '../about-us/about-us.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { TandcComponent } from '../tandc/tandc.component';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  isRegisterPage: boolean = false; 
  isCustomerLoggedin: boolean = false;

  userDisplayName = '';

  constructor(
    public dialogRef: MatDialog,
    private router: Router,
    private authService: AuthService,

  ) { }

  openDialog() {
    this.dialogRef.open(AboutUsComponent);
  }

  openContactDialog() {
    this.dialogRef.open(ContactUsComponent);
  }

  openTCDialog() {
    this.dialogRef.open(TandcComponent);
  }

  openPrivacyPolicyDialog() {
    this.dialogRef.open(PrivacyComponent);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.authService.getregisterpageStatus().subscribe(
        async (resp) => { this.isRegisterPage = await resp }
      );
    });

    this.authService.isLoggedIn.subscribe(res => this.isCustomerLoggedin = res);

  }

  get user(): any {
    return this.authService.GetUser();
  }

  onLogout() {
    this.authService.logout();
  }
}
