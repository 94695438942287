import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialog,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.setregisterpageStatus(true);
  }

}
