import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '../../../services/profile.service';
import { NotificationService } from '../../../services/notification.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.css']
})
export class BookingHistoryComponent implements OnInit {

  bookingHistory = [];

  constructor(
    private authService: AuthService,
    private SpinnerService: NgxSpinnerService,
    private service: ProfileService,
    private notifyService: NotificationService
  ) { }

  ngOnInit(): void {

    this.GetBookingHistory();

  }

  GetBookingHistory() {
    const email = this.authService.GetUserName();
    if (email) {
      this.service.GetBookingHistory(email).pipe(first()).subscribe(
        (res: any) => {
          this.bookingHistory = res;
        },
        error => {

        });
    }
  }
}
