import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private notifier: NotifierService) { }

  showSuccess(message) {
    this.notifier.notify('success', message);
  }

  showError(message) {
    this.notifier.notify('error', message);
  }

  showInfo(message) {
    this.notifier.notify('info', message);
  }

  showWarning(message) {
    this.notifier.notify('warning', message);
  }

  hideAllNotifications(): void {
    this.notifier.hideAll();
  }
}
