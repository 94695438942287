import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUrl = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,

  ) { }


  //get address list to populate on component
  getAddressList(email) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/profile/getAddressList?email=' + email, httpOptions);
  }

  //add new address
  addNewAddress(formData: FormData) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.post(this.baseUrl + '/profile/addNewAddress', JSON.stringify(formData), httpOptions);
  }

  //edit profile address based on id
  editAddress(formData: FormData) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.post(this.baseUrl + '/profile/editAddress', JSON.stringify(formData), httpOptions);
  }

  //show address data to edit
  ShowAddressToEdit(addressID) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/profile/ShowAddressToEdit?addressID=' + addressID, httpOptions);
  }

  //delete address
  DeleteAddress(addressID) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.post(this.baseUrl + '/profile/DeleteAddress?addressID=' + addressID, httpOptions);

  }

  //get basic detail to show on profile component
  getBasicProfile(email) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/profile/getBasicProfile?email=' + email, httpOptions);
  }

  //edit profile address based on id
  UpdateBasicProfile(formData: FormData) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.post(this.baseUrl + '/profile/UpdateBasicProfile', JSON.stringify(formData), httpOptions);
  }

  //get booking history to populate on booking history component
  GetBookingHistory(email) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/profile/GetBookingHistory?email=' + email, httpOptions);
  }

}
