import { Injectable, Inject, Optional } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { first, map, tap, retry, mergeMap, switchMap, filter, take, finalize } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private notifyService: NotificationService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          return this.handleUnauthorized(req, next);
        } else if (error.status == 400) {          

         if (error.error.message) {
            this.notifyService.showError(error.error.message);
          }
          else {
            this.notifyService.showError(error.error);
          }


        } else if (error.status == 404) {
          this.notifyService.showError(error.error.message);
        } else if (error.status == 403) {
          this.notifyService.showError('Forbidden: Unauthorized Access');
        } else {
          this.notifyService.showError(error.statusText);
        }
        return throwError(error);
      })
    );
  }

  updateHeader(req) {
    const authToken = this.authService.getToken();
    req = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken),
    });
    return req;
  }

  handleUnauthorized(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    // get a new token via authService.refreshToken
    if (!this.isRefreshingToken) {
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;

      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          // did we get a new token retry previous request
          if (res) {
            sessionStorage.setItem('access_token', res.token);
            sessionStorage.setItem('refresh_token', res.refreshToken);

            this.tokenSubject.next(res.token);

            return next.handle(this.updateHeader(req));
          }

          // If we don't get a new token, we are in trouble so logout.
          this.authService.logout();
          this.notifyService.showError('Session Expired. Try login again');

          return throwError;
        }),
        catchError((error) => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          //this.authService.logout();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.updateHeader(req));
        })
      );
    }
  }
}
