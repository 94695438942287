import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GolfService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  //get golf course list to populate dropdown
  getGolfCourseList() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Accept': '*/*'
      })
    }

    return this.httpClient.get(this.baseUrl + '/golf/getGolfCourseList', httpOptions);
  }


  //check if golf complimentary service is available or not
  CheckGolfServiceAvailable(email) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/golf/CheckGolfServiceAvailable?email=' + email, httpOptions);
  }

  //submit airport transfer form
  SubmitGolfBookingForm(formData) {

    const httpOptions = {
      headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
    }

    return this.httpClient.post(this.baseUrl + '/golf/SubmitGolfBookingForm', JSON.stringify(formData), httpOptions);
  }

  //invoke payment gatway for aurport booking url
  InitGolfPaymentGateway(cust_id: any) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': '*/*' })
    }

    return this.httpClient.get(this.baseUrl + '/golf/InitGolfPaymentGateway?cust_id=' + cust_id, httpOptions);
  }

}
