import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  TransactionID: any;
  transMessage: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: PaymentService,
    private SpinnerService: NgxSpinnerService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    let returnstring;
    this.activatedRoute.queryParams.subscribe(params => {
      returnstring = params.returnstring;
    });

    if (this.authService.GetUserName()) {
      this.authService.loggedIn.next(true);
    }

    this.success(returnstring);

  }

  success(returnstring: any) {
   
    this.service.success(returnstring).pipe(first()).subscribe(
      (res: any) => {

        this.TransactionID = res.map(x => x.respValue);
        this.transMessage = res.map(x => x.reason);        
      },
      error => {
        
      });
  }

}
